export const GalleryHomeSection = () => {
    return (
        <div className="home_gallery_section">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-5 row-gap-10 lg:grid-cols-2">
          <div className="flex flex-col  justify-center">
            <div className="max-w-xl mb-6">
            <p className="text-5xl font-extrabold text-white leading-none sm:text-4xl xl:text-4xl">
            🎨 NFT Gallery
                </p>
                <p className="text-2xl font-extralight text-white leading-none sm:text-2xl xl:text-2xl">
                Collaborations with NFTs Artists
                  </p>
                <br></br>
              <div
                className='bg-white flex rounded bg-white  flex-col justify-between m-5 p-5 border-b sm:p-10 lg:border-b-0 lg:border-r'
              >
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight sm:text-4xl sm:leading-none">
                LEAVING THE CAVE
              </h2>
              <p className="text-base  md:text-lg">
              This new collection imagines a scenario in which some animals set themselves free from the magical walls of those caves that are deemed to have been housing the very first examples of the art history for ages. 
              </p>
              <br></br>
              <p className="text-base  md:text-lg">
              They are ready to set out to a new life more powerful than ever with a special touch from the artist Duo Backtopoints. The Collection has 10 unique animals in total.
              </p>
              <br></br>
              <div>
              <a
                href="/gallery"
                className="inline-flex items-center justify-center bg-black h-12 px-6 font-semibold tracking-wide text-white transition duration-200 rounded shadow-md hover:text-deep-purple-900 bg-teal-accent-400 hover:bg-deep-purple-accent-100 focus:shadow-outline focus:outline-none"
              >
                Discover the Collection
              </a>
            </div>
              </div>
            </div>
          </div>
          <div>
            <img
              className="object-cover w-full h-full rounded shadow-lg "
              src={require('../../assets/backtopoints_cover.gif')}
              alt=""
            />
          </div>
        </div>
      </div>
      </div>
    );
  };