
import '../App.css';

import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import HeaderMenu from "./../components/Shared/HeaderMenu";
import Hero from "./../components/Landing/HomeHero";
import { Footer } from "./../components/Shared/Footer";
import { TikTokWall } from "./../components/Landing/TikTokWall";
import { MuseumHomeSection } from "./../components/Landing/HomeMuseumSection";
import { GalleryHomeSection } from "./../components/Landing/GalleryHomeSection";
import AboutSection from "./../components/AboutSection";
import ParticlesBg from 'particles-bg'
import raw_data from "./knowledge/articles.json"


function Knowledge() {
    const [articlesArray, setArticlesArray] = useState([]);

    useEffect(() => {
        var localArticlesArray = []
        raw_data.forEach((data) => {
            localArticlesArray.push(data)
        })
        console.log('localArticlesArray: ', localArticlesArray);
        setArticlesArray(localArticlesArray);
    }, []);

    const RenderArticleContent1 = () => {
        var counter = 0;
        return articlesArray.map((article, i) => {
            console.log('article: ', article);

            if (i < 3) {
                
                return (
                    <div className="sm:col-span-3 bg-white  lg:col-span-1p-8 rounded shadow-xl mr-3 text-center  sm:p-16">
                        <h2 className="w-full bg-white font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-none">
                            <a
                                href={'/knowledge/' + article.url}
                                aria-label="Knowledge"
                                title={article.title}
                                className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                            >
                                {article.title}
                            </a>
                        </h2>
                    </div>
                )
            }
        });
    }
    const RenderArticleContent2 = () => {
        var counter = 0;
        return articlesArray.map((article, i) => {
            console.log('article: ', article);

            if (i > 2 && i < 6) {
                
                return (
                    <div className="sm:col-span-3 bg-white  lg:col-span-1p-8 rounded shadow-xl mr-3 text-center  sm:p-16">
                        <h2 className="w-full bg-white font-sans text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-none">
                            <a
                                href={'/knowledge/' + article.url}
                                aria-label="Knowledge"
                                title={article.title}
                                className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                            >
                                {article.title}
                            </a>
                        </h2>
                    </div>
                )
            }
        });
    }


    return (
        <>
            <HeaderMenu whiteNav={false} />
            <div className="knowledge_landing_hero_section">


                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full  lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="p-8 rounded shadow-xl bg-white sm:p-16">
                        <div className="flex flex-col lg:flex-row">
                            <div className="mb-6 lg:mb-0 lg:w-1/2 lg:pr-5">
                                <h2 className="font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                                    📚 Knowledge Library
                                </h2>
                            </div>
                            <div className="lg:w-1/2">
                                <p className="mb-4 text-base text-gray-700">
                                    Guides, Walkthroughs and much more
                                </p>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full  lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="flex flex-column lg:flex-column text-center	">
                        <RenderArticleContent1 />
                        
                        
                    </div>
                </div>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full  lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="flex flex-column lg:flex-column text-center	">
                    <RenderArticleContent2 />
                        
                        
                    </div>
                </div>
                

            </div>
            <Footer />
        </>
    );
}

export default Knowledge;


