import './../App.css';
import { useState } from 'react';
import TagManager from 'react-gtm-module'
import { useParams } from "react-router-dom";
import { Text } from '@chakra-ui/react';
import { ColorRing } from 'react-loader-spinner';

const reader = require("g-sheets-api");



const tagManagerArgs = {
    gtmId: 'G-TELW6X57XD'
}


const options = {
    apiKey: 'AIzaSyAA9yYJUO8ic_gsxBFOet2K56fRrQjOL6s',
    sheetId: '1OvbeqFzuFc6wSdeZJRj-tbG8EypnkypH5XyK603Q9-o',
    sheetNumber: 1,
    returnAllResults: true,
  }
// const readerOptions = {
//   sheetId: "2PACX-1vQikmG513KQbwdvPgE1QhaFkH7QFIE-Al2yxgTGOWVe_bJjN4kr8KVA1g8MkxKeUvnYH8vqMmDAej2X",
//   returnAllResults: false,
// };

// https://docs.google.com/spreadsheets/d/e/2PACX-1vQikmG513KQbwdvPgE1QhaFkH7QFIE-Al2yxgTGOWVe_bJjN4kr8KVA1g8MkxKeUvnYH8vqMmDAej2X/pub?output=csv

TagManager.initialize(tagManagerArgs)

function Animal() {
    const params = useParams();
    const animal_id = params.id;
    
    reader(options, (results) => {
        /* Do something amazing with the results */
        console.log(results);
        for (let index = 0; index < results.length; index++) {
            const local_animal_id = results[index]['animal_id'];
            const local_animal_url = results[index]['animal_url'];
            if (local_animal_id === animal_id){
                console.log('found it, will redirect to: ' + local_animal_url);
                window.location.href = local_animal_url; 
                return null;
            }
            
        }
        
    });

    return (
        <div className="px-12 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-12 lg:py-20" style={{display: 'flex'}}>
    
        <div className="flex flex-col lg:flex-row">
          <div className="sm:col-span-12 lg:col-span-12">
            <div className="mb-12 flex items-center">
    <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
            </div>
            </div>
            </div>
            </div>
    )
}

export default Animal;
