import { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import HomeHero from '../Landing/HomeHero';
const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

const HeaderMenu = (props) => {
  const black_logo = require('./../../assets/site_logo.webp');
  const white_logo = require('./../../assets/site_logo_white.webp');

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  

  return (
    <div className={`${props.homepage ? "home_hero_section" : ""}`}>
      
      <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 ">
        <div className="relative flex items-center justify-between">
          <a
            href="/"
            aria-label="nftmuseum.gr"
            title="nftmuseum.gr"
            className="inline-flex items-center"
          >
            <img
              className="object-contain h-auto w-10 ..."
              src={props.whiteNav ? white_logo  : black_logo}
              alt="logo">
            </img>
            <span className={`ml-2 text-xl font-bold tracking-wide ${props.whiteNav ? 'text-white' : 'text-gray-800 '} uppercase`}>
              nftmuseum.gr
            </span>
          </a>
          <ul className="flex items-center hidden space-x-8 lg:flex">
            <li>
              <a
                href="/"
                aria-label="Home"
                title="Home"
                className={`font-medium tracking-wide ${props.whiteNav ? 'text-white' : 'text-gray-700 '} transition-colors duration-200 hover:text-deep-purple-accent-400`}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/museum"
                aria-label="Museum"
                title="Museum"
                className={`font-medium tracking-wide ${props.whiteNav ? 'text-white' : 'text-gray-700 '} transition-colors duration-200 hover:text-deep-purple-accent-400`}
              >
                🏛️  Museum
              </a>
            </li>
            <li>
              <a
                href="/gallery"
                aria-label="NFT Gallery"
                title="NFT Gallery"
                className={`font-medium tracking-wide ${props.whiteNav ? 'text-white' : 'text-gray-700 '} transition-colors duration-200 hover:text-deep-purple-accent-400`}
              >
                🎨  Gallery
              </a>
            </li>
            <li>
              <a
                href="/about"
                aria-label="about"
                title="About"
                className={`font-medium tracking-wide ${props.whiteNav ? 'text-white' : 'text-gray-700 '} transition-colors duration-200 hover:text-deep-purple-accent-400`}
              >
                About
              </a>
            </li>
            <li>
            <a
              href="/knowledge"
              aria-label="Knowledge"
              title="Knowledge Library"
              className={`font-medium tracking-wide ${props.whiteNav ? 'text-white' : 'text-gray-700 '} transition-colors duration-200 hover:text-deep-purple-accent-400`}
            >
              📚  Knowledge Library
            </a>
            </li>
            {/* <li>
            <a
              href="/"
              aria-label="About us"
              title="About us"
              className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              About us
            </a>
          </li>
          <li>
            <a
              href="/"
              className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              aria-label="Sign up"
              title="Sign up"
            >
              Sign up
            </a>
          </li> */}
          </ul>
          <div className="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg className="w-5 text-white" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
              <div className="absolute top-0 left-0 w-full">
                <div className="p-5 bg-white border rounded shadow-sm">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <a
                        href="/"
                        aria-label="nftmuseum.gr"
                        title="nftmuseum.gr"
                        className="inline-flex items-center"
                      >
                        <img
                          className="object-contain h-auto w-10 ..."
                          // src="./../../assets/site_logo.webp"
                          src={require('./../../assets/site_logo.webp')}
                          alt="logo">
                        </img>
                        <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                          nftmuseum.gr
                        </span>
                      </a>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul className="space-y-4">
                      <li>
                        <a
                          href="/"
                          aria-label="Home"
                          title="Home"
                          className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Home
                        </a>
                      </li>
                      <li>
                        <a
                          href="/museum"
                          aria-label="Museum"
                          title="Museum"
                          className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          🏛️  Museum
                        </a>
                      </li>
                      <li>
                        <a
                          href="/gallery"
                          aria-label="Gallery"
                          title="Gallery"
                          className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          🎨  Gallery
                        </a>
                      </li>
                      <li>
                        <a
                          href="/about"
                          aria-label="About"
                          title="About"
                          className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          About us
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {props.homepage && (
        <HomeHero />
      )}
    </div>

  );
};

export default HeaderMenu;