
import './../App.css';

import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import HeaderMenu from "./../components/Shared/HeaderMenu";
import Hero from "./../components/Landing/HomeHero";
import {Footer} from "./../components/Shared/Footer";
import {TikTokWall} from "./../components/Landing/TikTokWall";
import {MuseumHomeSection} from "./../components/Landing/HomeMuseumSection";
import {GalleryHomeSection} from "./../components/Landing/GalleryHomeSection";
import {Newsletter} from "./../components/Landing/Newsletter";

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-TELW6X57XD'
}
TagManager.initialize(tagManagerArgs)

function Home() {

    useEffect(() => {
      
    }, []);

    return (
        <>
        <HeaderMenu whiteNav={true} homepage={true}/>
        {/* <Newsletter /> */}
        <MuseumHomeSection/>
        <GalleryHomeSection />
        {/* <TikTokWall/> */}
        <Footer/>
        </>
    );
}

export default Home;


