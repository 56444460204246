
import '../App.css';

import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import HeaderMenu from "./../components/Shared/HeaderMenu";
import Hero from "./../components/Landing/HomeHero";
import {Footer} from "./../components/Shared/Footer";
import {TikTokWall} from "./../components/Landing/TikTokWall";
import {MuseumHomeSection} from "./../components/Landing/HomeMuseumSection";
import {GalleryHomeSection} from "./../components/Landing/GalleryHomeSection";
import AboutSection from "./../components/AboutSection";


function About() {

    useEffect(() => {
      
    }, []);

    return (
        <>
    <HeaderMenu whiteNav={true}/>
        <AboutSection/>
        {/* <TikTokWall/> */}
        <Footer/>
        </>
    );
}

export default About;


