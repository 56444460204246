import { useEffect, useState } from 'react';

import TagManager from 'react-gtm-module'


const MuseumEmbedded = (props) => {
  
  useEffect(() => {
  }, []);

  return (
    <div className="App">
    <div
        style={{
            width: '100vw',
            height: '100vh'
        }}
        dangerouslySetInnerHTML={{ __html: "<iframe style='width: 100vw; height: 100vh' src='https://www.muse.place/nftgallerygr' />" }} />
</div>
  );
};


export default MuseumEmbedded;