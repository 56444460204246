import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module'





const Hero = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    
  }, []);

  return (
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 ">
        <div className="flex flex-col lg:flex-row">
          <div className="sm:col-span-12 lg:col-span-12">
            <div className="mb-12">

              <p className="text-3xl font-extrabold text-white leading-none sm:text-4xl xl:text-4xl">
              1st Greek Digital Musuem
              </p>
              <p className="text-1xl font-light text-white leading-none sm:text-4xl xl:text-3xl">
              The first Greek digital museum that will host history from the world of NFTs. A world digitally created without origin, skin color or dogmas. 
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col  rounded shadow-sm md:justify-center lg:flex-row">
          <div className="flex rounded bg-white flex-col justify-between m-5 p-5  sm:p-10 lg:w-1/2">
          <a
                href="/museum"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                 <div>
              <p className="mb-2 text-xs font-semibold tracking-wide uppercase">
                New!
              </p>
              <h5 className="max-w-md mb-6 text-3xl font-extrabold leading-none sm:text-4xl">
              🏛️ NFT Museum
              </h5>
              <p className="mb-6 text-base text-gray-700 md:text-lg sm:mb-8">
                The Beeple Story
              </p>
            </div>
              </a>
           
           
          </div>
          <div className="flex rounded bg-white  flex-col justify-between m-5 p-5 border-b sm:p-10 lg:border-b-0 lg:border-r lg:w-1/2">
            
            <a
                href="/gallery"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-deep-purple-accent-400 hover:text-deep-purple-800"
              >
                <div>
              <p className="mb-2 text-xs font-semibold tracking-wide uppercase">
                New collection available
              </p>
              <h5 className="max-w-md mb-6 text-3xl font-extrabold leading-none sm:text-4xl">
              🎨 NFT Gallery
              </h5>
              <p className="mb-6 text-base text-gray-700 md:text-lg sm:mb-8">
              "LEAVING THE CAVE" Collection
              </p>
            </div>
              </a>
              </div>      
        </div>
      </div>
  );
};


export default Hero;