import './App.css';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import TagManager from 'react-gtm-module'
import Gallery from './pages/Gallery';
import Museum from './pages/Museum';
import Home from './pages/Home';
import About from './pages/About';
import Animal from './pages/Animal';
import KnowledgeTemplate from './pages/KnowledgeTemplate';
import Knowledge from './pages/Knowledge';


const tagManagerArgs = {
  gtmId: 'G-TELW6X57XD'
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/museum" element={<Gallery />} />
        <Route path="/gallery" element={<Museum />} />
        <Route path="/about" element={<About />} />
        <Route path="/knowledge" element={<Knowledge />} />
        
        <Route path="/" element={<Home />} />
        <Route path="/animal/:id" element={<Animal />} />
        <Route path="/knowledge/:id" element={<KnowledgeTemplate />} />
      </Routes>
    </Router>

  );
}

export default App;
