import './../App.css';

import TagManager from 'react-gtm-module'
import GalleryEmbedded from "./../components/GalleryEmbedded";
import {Footer} from "./../components/Shared/Footer";
import {TikTokWall} from "./../components/Landing/TikTokWall";
import HeaderMenu from "./../components/Shared/HeaderMenu";

const tagManagerArgs = {
    gtmId: 'G-TELW6X57XD'
}

TagManager.initialize(tagManagerArgs)

function Gallery() {
    return (
        <GalleryEmbedded/>
        // <>
        // <HeaderMenu />
        // <GalleryEmbedded/>
        // <TikTokWall/>
        // <Footer/>
        // </>
    );
}

export default Gallery;
