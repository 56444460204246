import ParticlesBg from 'particles-bg'

export const MuseumHomeSection = () => {
    return (
      <>
      <ParticlesBg type="cobweb" bg={true} />
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    
        <div className="flex flex-col lg:flex-row">
          <div className="sm:col-span-12 lg:col-span-12">
            <div className="mb-12 flex items-center">
            {/* <div className="z-0 flex justify-center h-full -mx-4 overflow-hidden lg:pt-24 lg:pb-16 lg:pr-8 xl:pr-0 lg:w-1/2 lg:absolute lg:justify-end lg:bottom-0 lg:left-0 lg:items-center">
                
              </div> */}
                <img
                  src="../../assets/museum_home_icon.webp"
                  className="object-cover object-right w-auto h-20 lg:w-auto lg:h-20"
                  alt=""
                />
                <div>
                <p className="text-5xl font-extrabold leading-none sm:text-4xl xl:text-4xl">
                🏛️ MUSEUM
                </p>
                <p className="text-2xl font-extralight leading-none sm:text-2xl xl:text-2xl">
                Documenting the NFT projects that had the biggest social impact, <br></br>through storytelling
                  </p>
                </div>
            </div>
            
          </div>
        </div>
        
        <div className="grid gap-8 lg:grid-cols-2 sm:max-w-sm sm:mx-auto lg:max-w-full">
        <a
                  href="/museum"
                  className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                  aria-label="Category"
                  title="traveling"
                > 
          <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
            <img
              src={require ("../../assets/beeple_cover.webp")}
              className="object-cover w-full h-64"
              alt=""
            />
            <div className="p-5 border border-t-0">
              <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                <a
                  href="/"
                  className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                  aria-label="Category"
                  title="traveling"
                >
                  Origin Stories
                </a>
                {/* <span className="text-gray-600"> April 17th - May 17th</span> */}
              </p>
              <a
                href="/"
                aria-label="Category"
                title="Visit the East"
                className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
              >
                Beeple: The first 500 days
              </a>
              <p className="mb-2 text-gray-700">
               EVERYDAYS Collection: The $69 million story
              </p>
            </div>
            
          </div>
          </a>

          <a
                  href="https://www.muse.place/veefriends"
                  target="_blank"
                  className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                  aria-label="Category"
                  title="traveling" rel="noreferrer"
                > 
          <div className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
            <img
              src={require ("../../assets/vee_friends.webp")}
              className="object-cover w-full h-64"
              alt=""
            />
            <div className="p-5 border border-t-0">
              <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                <a
                  href="/"
                  className="transition-colors duration-200 text-blue-gray-900 hover:text-deep-purple-accent-700"
                  aria-label="Category"
                  title="traveling"
                >
                  Origin Stories
                </a>
                {/* <span className="text-gray-600"> April 17th - May 17th</span> */}
              </p>
              <a
                href="/"
                aria-label="Category"
                title="Visit the East"
                className="inline-block mb-3 text-2xl font-bold leading-5 transition-colors duration-200 hover:text-deep-purple-accent-700"
              >
                VeeFriends & VeeCon
              </a>
              <p className="mb-2 text-gray-700">
              Gary Vaynerchuk enters the NFT space
              </p>
            </div>
            
          </div>
          </a>

        </div>

        
      </div>
      </>
    );
  };