import './../App.css';

import TagManager from 'react-gtm-module'
import MuseumEmbedded from "./../components/MuseumEmbedded";
import {Footer} from "./../components/Shared/Footer";
import {TikTokWall} from "./../components/Landing/TikTokWall";
import HeaderMenu from "./../components/Shared/HeaderMenu";


const tagManagerArgs = {
    gtmId: 'G-TELW6X57XD'
}

TagManager.initialize(tagManagerArgs)

function Museum() {
  return (
    <MuseumEmbedded/>
    // <>
    //     <HeaderMenu />
    //     <MuseumEmbedded/>
    //     <TikTokWall/>
    //     <Footer/>
    //     </>
  );
}

export default Museum;
