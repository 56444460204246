import './../App.css';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module'
import { useParams } from "react-router-dom";
import { Text } from '@chakra-ui/react';
import { ColorRing } from 'react-loader-spinner';
import ReactMarkdown from 'react-markdown'
import { Footer } from "./../components/Shared/Footer";
import HeaderMenu from "./../components/Shared/HeaderMenu";
import raw_data from "./knowledge/articles.json"

const tagManagerArgs = {
    gtmId: 'G-TELW6X57XD'
}


const options = {
    apiKey: 'AIzaSyAA9yYJUO8ic_gsxBFOet2K56fRrQjOL6s',
    sheetId: '1OvbeqFzuFc6wSdeZJRj-tbG8EypnkypH5XyK603Q9-o',
    sheetNumber: 1,
    returnAllResults: true,
}

TagManager.initialize(tagManagerArgs);



const KnowledgeTemplate = () => {
    const params = useParams();
    const term_id = params.id;
    const [content_data, use_content_data] = useState([]);
    
    useEffect(() => {
        for (var i=0; i < raw_data.length; i++) {
            if (raw_data[i].url === term_id){
                console.log('found it: ', raw_data[i]);
                use_content_data(raw_data[i].content);
                break;
            }
        }
      }, []);
    
    
    const RenderArticleContent = () => {
        
        return content_data.map((text_piece, i) => {
            console.log(text_piece);
            if (text_piece.type === 'title'){
                return (
                    <>
                    <p className="text-5xl font-extrabold leading-none sm:text-4xl xl:text-4xl">{text_piece.value}</p>
                    <br></br>
                    <br></br>
                    </>
                )
            } else if (text_piece.type === 'subtitle'){
                return (
                    <>
                    <br></br>
                    <p className="text-2xl font-bold leading-none sm:text-2xl xl:text-2xl">{text_piece.value}</p>
                    <br></br>
                    </>
                )
            } else if (text_piece.type === 'text'){
                return (
                    <>
                    <p className="text-2xl font-extralight leading-none sm:text-2xl xl:text-2xl">{text_piece.value}</p>
                    <br></br>
                    </>
                )
            } else {
                return (
                    <>
                    <p className="text-2xl font-extralight leading-none sm:text-2xl xl:text-2xl">{text_piece.value}</p>
                    <br></br>
                    </>
                )
            }
            
        });
    }

    return (
        <>
            <HeaderMenu whiteNav={false} homepage={false} />
            <KnowledgeTemplateHero/>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">

                <div className="flex flex-col lg:flex-row">
                    <div className="sm:col-span-12 lg:col-span-1">
                        <div className="mb-12 knowledge_formatting">
                            <div>
                                <RenderArticleContent />
                            </div>
                        </div>

                    </div>
                    {/* <div className="sm:col-span-2 lg:col-span-2">
                        <div className="mb-12 knowledge_formatting">
                            <div>
                                <RenderArticleContent />
                            </div>
                        </div>

                    </div> */}
                    
                </div>
            </div>

            <Footer />
        </>

    )
}

const KnowledgeTemplateHero = (props) => {
    
  
    return (
        <div className='knowledge_hero_section'>
        <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-10 lg:px-8 lg:py-10 ">
          <div className="flex flex-col lg:flex-row">
            <div className="sm:col-span-12 lg:col-span-12">
              <div className="mb-12">
  
                <p className="text-3xl font-extrabold text-white leading-none sm:text-4xl xl:text-4xl">
                📚 Knowledge Library
                </p>
                {/* <p className="text-1xl font-light text-white leading-none sm:text-4xl xl:text-3xl">
                
                </p> */}
              </div>
            </div>
          </div>
          
        </div>
        </div>
    );
  };
  
export default KnowledgeTemplate;
